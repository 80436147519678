// src/components/PdfViewer.js
import React from "react";

// // Set PDF worker path (needed for react-pdf to work in Gatsby)
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfViewer = ({ fileUrl }) => {
  return (
    <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
      <iframe
        src={fileUrl}
        title="PDF Viewer"
        width="100%"
        height="800px"
        style={{ border: "none" }}
      />
    </div>
  );
};

export default PdfViewer;
