// This is a standalone page that renders any given PDF from URL query
import React from "react";
// import { useLocation } from "@reach/router";
import PdfViewer from "../../components/atoms/PdfViewer";

const PdfViewerPage = () => {
  return (
    <div>
      <PdfViewer fileUrl={"/documents/Haken.pdf"} />
    </div>
  );
};

export default PdfViewerPage;
